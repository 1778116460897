import { Injectable } from '@angular/core';
import { ActionWithPayload } from './action-with-payload';
import { Wallet } from '@app/models/wallet';

@Injectable()
export class WalletActions {

  static GET_Wallet_DATA = 'GET_Wallet_DATA';
  getWalletData(userId: number): ActionWithPayload<number> {
    return {
      type: WalletActions.GET_Wallet_DATA,
      payload: userId
    };
  }


  static GET_Wallet_DATA_SUCCESS = 'GET_Wallet_DATA_SUCCESS';
  getWalletDataSuccess(data: Wallet): ActionWithPayload<Wallet> {
    return {
      type: WalletActions.GET_Wallet_DATA_SUCCESS,
      payload: data
    };
  }
}
