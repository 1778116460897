export class LoginResponse {
  token: string;
  user?: User;
}

export class User {
  id?: number;
  name?: string;
  email?: string;
  constructor(options) {
    Object.assign(this, options);
  }
}
