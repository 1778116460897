<ng-template #modalTemplate>
  <div class="card p-0" style="min-width: fit-content;">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <ng-content select="[heading]"></ng-content>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span style="color: #fff;" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body m-2" style="max-height: calc(100vh - 170px);overflow-y: auto;">
      <ng-container *ngTemplateOutlet="templateRef"></ng-container>
    </div>
  </div>
</ng-template>