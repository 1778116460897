import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { RedirectingPageComponent } from './components/redirecting-page/redirecting-page.component';
import { FullLayoutComponent } from './container/full-layout/full-layout.component';
import { PendingChangesGuard } from './services/component-can-deactivate.service';

// Import Containers
export const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        data: {
          type: 'home',
          title: 'Home',
        },
      },
      {
        path: 'transcript',
        loadChildren: () => import('./modules/transcript/transcript.module').then((m) => m.TranscriptModule),
        data: {
          title: 'Transcript',
        },
      },
      // {
      //   path: 'transactions',
      //   loadChildren: () => import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule),
      //   data: {
      //     title: 'Transactions',
      //   },
      // },
      {
        path: 'contact-us',
        loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'features',
        loadChildren: () => import('./pages/feature/feature.module').then(m => m.FeatureModule)
      },
      {
        path: 'industries',
        loadChildren: () => import('./pages/industries/industries.module').then(m => m.IndustriesModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
      {
        path: 'terms-of-service',
        loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
      },
      {
        path: 'audio-to-text',
        loadChildren: () => import('./pages/audio-to-text/audio-to-text.module').then(m => m.AudioToTextModule)
      },
      {
        path: 'video-to-text',
        loadChildren: () => import('./pages//video-to-text/video-to-text.module').then(m => m.VideoToTextModule)
      },
      {
        path: 'checkout',
        loadChildren: () => import('./modules/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'redirecting',
        component: RedirectingPageComponent,
      },
    ]
  },
];
export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
