import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../services/login.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onResetPassword: EventEmitter<null> = new EventEmitter<null>();
  loginData: { name?: string; email?: string; password?: string } = {};
  newUser: boolean = false;
  forgotPassword: boolean = false;
  constructor(private loginService: LoginService) { }

  ngOnInit() { }

  resetForm() {
    this.loginData = {};
  }

  onLogin() {
    if (this.loginData.name) delete this.loginData.name;
    this.loginService.get$(this.loginData).subscribe((res: any) => {
      if (res.ok) {
        this.resetForm();
        this.onSuccess.emit(res.body);
      }
    });
  }


  signUp() {
    this.loginService.create$(this.loginData).subscribe((res: any) => {
      if (res.ok) {
        this.onLogin();
      }
    });
  }

  onForgot() {
    this.forgotPassword = true;
  }

  resetPassword() {
    this.loginService.forgotPassword$(this.loginData.email).subscribe(res => {
      this.resetForm();
      Swal.fire(
        "Check Email",
        `A new password has been generated and sent to your email address if it exists.`,
        "success"
      );
      this.onResetPassword.emit();
    })
  }
}
