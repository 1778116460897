import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Constant } from '@app/shared/constant';
import { WalletActions } from '@app/store';
import { Store } from '@ngrx/store';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../store/actions/wallet.actions";
import * as i4 from "./alert.service";
export class WalletService {
    constructor(http, store, walletActions, alertService) {
        this.http = http;
        this.store = store;
        this.walletActions = walletActions;
        this.alertService = alertService;
        this.apiURL = environment.api.URL;
    }
    getWalletInfo$(userId) {
        return new Observable(observer => {
            const url = `${this.apiURL}${environment.api.version}${Constant.API.users}/${userId}/${Constant.API.wallet}`;
            this.http.get(url).subscribe((res) => {
                observer.next(res);
            }, err => observer.error(err));
        });
    }
    refreshWalletData(userId) {
        this.store.dispatch(this.walletActions.getWalletData(userId));
    }
    updateWalletSetting$(userId, params) {
        return new Observable(observer => {
            this.http.put(this.apiURL + environment.api.version + Constant.API.users + '/' + userId, params)
                .subscribe((data) => {
                observer.next(data);
                this.alertService.openSnackBar('Wallet settings updated', '');
            }, err => {
                this.alertService.openSnackBar('Failed', '');
                observer.error(err);
            });
        });
    }
}
WalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.WalletActions), i0.ɵɵinject(i4.AlertService)); }, token: WalletService, providedIn: "root" });
