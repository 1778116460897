import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SavedCard } from '@app/models/saved-card';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {
  @Output() removeCard: EventEmitter<string> = new EventEmitter<string>();
  @Output() makeDefault: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<null> = new EventEmitter<null>();
  @Output() pay: EventEmitter<null> = new EventEmitter<null>();
  @Input() showSettings: boolean = false;
  @Input() defaultCard: boolean = false;
  @Input() selected: boolean = false;
  @Input() cardInfo: SavedCard

  constructor() { }

  ngOnInit() { }

  onCick() {
    this.onSelect.emit();
  }

  onPay() {
    this.pay.emit();
  }

  onMakeDefault() {
    this.makeDefault.emit(this.cardInfo.id);
  }

  onRemoveCard() {
    this.removeCard.emit(this.cardInfo.id);
  }

}
