import { ExtraOptions, Routes } from '@angular/router';
import { RedirectingPageComponent } from './components/redirecting-page/redirecting-page.component';
import { FullLayoutComponent } from './container/full-layout/full-layout.component';
const ɵ0 = () => import("./pages/home/home.module.ngfactory").then((m) => m.HomeModuleNgFactory), ɵ1 = {
    type: 'home',
    title: 'Home',
}, ɵ2 = () => import("./modules/transcript/transcript.module.ngfactory").then((m) => m.TranscriptModuleNgFactory), ɵ3 = {
    title: 'Transcript',
}, ɵ4 = () => import("./pages/contact-us/contact-us.module.ngfactory").then(m => m.ContactUsModuleNgFactory), ɵ5 = () => import("./pages/faq/faq.module.ngfactory").then(m => m.FaqModuleNgFactory), ɵ6 = () => import("./pages/feature/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ7 = () => import("./pages/industries/industries.module.ngfactory").then(m => m.IndustriesModuleNgFactory), ɵ8 = () => import("./pages/privacy-policy/privacy-policy.module.ngfactory").then(m => m.PrivacyPolicyModuleNgFactory), ɵ9 = () => import("./pages/terms-of-service/terms-of-service.module.ngfactory").then(m => m.TermsOfServiceModuleNgFactory), ɵ10 = () => import("./pages/audio-to-text/audio-to-text.module.ngfactory").then(m => m.AudioToTextModuleNgFactory), ɵ11 = () => import("./pages/video-to-text/video-to-text.module.ngfactory").then(m => m.VideoToTextModuleNgFactory), ɵ12 = () => import("./modules/checkout/checkout.module.ngfactory").then((m) => m.CheckoutModuleNgFactory), ɵ13 = () => import("./modules/dashboard/dashboard.module.ngfactory").then((m) => m.DashboardModuleNgFactory), ɵ14 = () => import("./modules/settings/settings.module.ngfactory").then((m) => m.SettingsModuleNgFactory);
// Import Containers
export const routes = [
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ0,
                data: ɵ1,
            },
            {
                path: 'transcript',
                loadChildren: ɵ2,
                data: ɵ3,
            },
            // {
            //   path: 'transactions',
            //   loadChildren: () => import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule),
            //   data: {
            //     title: 'Transactions',
            //   },
            // },
            {
                path: 'contact-us',
                loadChildren: ɵ4
            },
            {
                path: 'faq',
                loadChildren: ɵ5
            },
            {
                path: 'features',
                loadChildren: ɵ6
            },
            {
                path: 'industries',
                loadChildren: ɵ7
            },
            {
                path: 'privacy-policy',
                loadChildren: ɵ8
            },
            {
                path: 'terms-of-service',
                loadChildren: ɵ9
            },
            {
                path: 'audio-to-text',
                loadChildren: ɵ10
            },
            {
                path: 'video-to-text',
                loadChildren: ɵ11
            },
            {
                path: 'checkout',
                loadChildren: ɵ12,
            },
            {
                path: 'dashboard',
                loadChildren: ɵ13,
            },
            {
                path: 'settings',
                loadChildren: ɵ14,
            },
            {
                path: 'redirecting',
                component: RedirectingPageComponent,
            },
        ]
    },
];
export const routingConfiguration = {
    paramsInheritanceStrategy: 'always',
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
