export const Constant = {
  API: {
    authenticate: "users/validate",
    forgotPassword: "users/forgotpassword",
    changePassword: "changepassword",
    jobs: "jobs",
    plans: "plan",
    contactUs: "contactus",
    cards: "cards",
    users: 'users',
    payments: 'payments',
    transcribe: {
      get: 'transcribe',
      start: 'starttranscribe',
    },
    billingDetails: 'billingDetails',
    wallet: "wallet"
  }
};
