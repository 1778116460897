import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from 'app/shared/constant';
import { ContactUs } from '@app/models/contact-us';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  constructor(private httpClient: HttpClient) { }

  create$(param: ContactUs) {
    const url = environment.api.URL + environment.api.version;
    return new Observable((observer) => {
      this.httpClient.post(`${url}${Constant.API.contactUs}`, param).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }
}
