import { Component, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [BsModalService],
})
export class ModalComponent {
  @ViewChild('modalTemplate', { static: false }) template;
  templateRef: TemplateRef<any>;
  @Output() onHidden: EventEmitter<null> = new EventEmitter<null>();
  @Output() onCancel: EventEmitter<null> = new EventEmitter<null>();
  modalRef: BsModalRef;
  config: ModalOptions = {
    animated: true,
    keyboard: true,
  };

  constructor(private modalService: BsModalService) {
    this.modalService.onHide.subscribe((res) => {
      if (res === 'backdrop-click') {
        this.onCancel.emit();
      } else {
        this.onHidden.emit();
      }
    });
  }

  show() {
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  hide() {
    this.modalService.hide(1);
  }
}
