import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-credit-info",
  templateUrl: "./credit-info.component.html",
  styleUrls: ["./credit-info.component.scss"]
})
export class CreditInfoComponent implements OnInit {
  @Output() onClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() refreshBalance: EventEmitter<null> = new EventEmitter<null>();
  @Output() loadBalance: EventEmitter<number> = new EventEmitter<number>();
  @Input() walletBalance: number = 0;
  @Input() loading: boolean = false;
  amountToLoad: number = 0;
  constructor() { }

  ngOnInit() { }

  addCredits() {
    if (this.amountToLoad >= 5) {
      this.amountToLoad = 0;
      this.onClick.emit();
    }
  }

  change() {
    this.amountToLoad = Number(this.amountToLoad.toString().replace(/^0+/, ''));
    this.loadBalance.emit(this.amountToLoad);
  }

  refreshWallet() {
    this.refreshBalance.emit();
  }
}
