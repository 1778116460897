import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(route, state);
    const token = this.authenticationService.getToken();
    // console.log(token);
    if (token && token.length > 0) {
      // console.log('Route allowed');
      return true;
    } else {
      this.router.navigate(['./']);
      console.log('Route not allowed');
      return false;
    }
  }
}
