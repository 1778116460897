import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
declare let document: any;

@Injectable()
export class StorageService {
  private DEFAULT_EXPIRY_IN_DAYS = 1;

  constructor() {}

  /**
   * Returns a cookie value
   * @param name Name of the cookie
   */
  getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c;
    for (let i = 0; i < caLen; i++) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Sets cookie
   * @param name Name of the cookie
   * @param value Value of the cookie
   * @param expireDays Expiry in days
   * @param path path or domain of the cookie
   */
  setCookie(name: string, value: string, expireDays?: number, path: string = '/', domain?: string) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `;path=.${domain ? domain : environment.domain};path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  /**
   * Deletes cookie
   * @param name Name of the cookie
   */
  deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }
}
