/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirecting-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./redirecting-page.component";
import * as i6 from "@angular/router";
var styles_RedirectingPageComponent = [i0.styles];
var RenderType_RedirectingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectingPageComponent, data: {} });
export { RenderType_RedirectingPageComponent as RenderType_RedirectingPageComponent };
export function View_RedirectingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "redirecting"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "mat-card", [["class", "text-center mat-card"], ["style", "font-size: 3rem;"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hang on... "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "div", [["style", "font-size: 5rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \uD83D\uDE80 "])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Taking you to home.. "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); }); }
export function View_RedirectingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirecting-page", [], null, null, null, View_RedirectingPageComponent_0, RenderType_RedirectingPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.RedirectingPageComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectingPageComponentNgFactory = i1.ɵccf("app-redirecting-page", i5.RedirectingPageComponent, View_RedirectingPageComponent_Host_0, {}, {}, []);
export { RedirectingPageComponentNgFactory as RedirectingPageComponentNgFactory };
