import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.scss']
})
export class ConfirmBoxComponent implements OnInit {
  @Output() proceed: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit() { }

  openAlert(jobId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to revert this.',
      // icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.confirmed(jobId);
        Swal.fire(
          'Success!',
          'Job has been deleted.',
          'success'
        )
      }
    })
  }


  cancel() { }

  confirmed(jobId) {
    this.proceed.emit(jobId);
  }

}
