import { ActionWithPayload, WalletActions } from '../actions';


export interface WalletState {
  balance: number;
  autoReload: boolean;
  reloadThreshold: number;
  reloadAmount: number;
}

export const walletInitialState: WalletState = {
  balance: 0,
  autoReload: false,
  reloadThreshold: null,
  reloadAmount: null
};



export function walletReducer(
  state: WalletState = walletInitialState,
  action: ActionWithPayload<any>
): WalletState {
  switch (action.type) {

    case WalletActions.GET_Wallet_DATA:
      return { ...state };

    case WalletActions.GET_Wallet_DATA_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        autoReload: action.payload.autoReload,
        reloadThreshold: action.payload.reloadThreshold,
        reloadAmount: action.payload.reloadAmount
      };

    default:
      return state;
  }
}
