import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from '../shared/constant';
import { AlertService } from '@app/services/alert.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
export class LoginService {
    constructor(httpClient, handler, alertService) {
        this.httpClient = httpClient;
        this.handler = handler;
        this.alertService = alertService;
        this.bypassedClient = new HttpClient(handler);
    }
    get$(params) {
        const url = environment.api.URL + environment.api.version;
        return new Observable((observer) => {
            this.bypassedClient.post(url + Constant.API.authenticate, params, { observe: 'response' }).subscribe((res) => {
                this.alertService.openSnackBar(`Hola! ${res.body.user.name}`, '');
                observer.next(res);
            }, (err) => {
                this.alertService.openSnackBar('OOPS! invalid username or password.', '');
                observer.error(err);
            });
        });
    }
    create$(params) {
        const url = environment.api.URL + environment.api.version;
        return new Observable((observer) => {
            this.bypassedClient.post(url + Constant.API.users, params, { observe: 'response' }).subscribe((res) => {
                observer.next(res);
            }, (err) => {
                this.alertService.openSnackBar(err.error.message, '');
                observer.error(err);
            });
        });
    }
    forgotPassword$(email) {
        const url = environment.api.URL + environment.api.version;
        return new Observable((observer) => {
            this.bypassedClient.get(`${url}${Constant.API.forgotPassword}?email=${email}`, { observe: 'response' }).subscribe((res) => {
                console.log(res);
                // this.alertService.openSnackBar(`Hola! ${res.body.user.name}`, '');
                observer.next(res);
            }, (err) => {
                this.alertService.openSnackBar('OOPS! email address doesnt exist', '');
                observer.error(err);
            });
        });
    }
    changePassword$(postData, userId) {
        const url = environment.api.URL + environment.api.version;
        return new Observable((observer) => {
            this.httpClient.post(`${url}${Constant.API.users}/${userId}/changepassword`, postData, { observe: 'response' }).subscribe((res) => {
                this.alertService.openSnackBar(`Password changed successfully`, '');
                observer.next(res);
            }, (err) => {
                this.alertService.openSnackBar(err.error.message, '');
                observer.error(err);
            });
        });
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.AlertService)); }, token: LoginService, providedIn: "root" });
