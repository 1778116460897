import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FileDurationService } from 'app/services/file-duration.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() onFileChange: EventEmitter<{ duration: number; file: any }> = new EventEmitter<{ duration: number; file: any }>();
  fileDuration: number = 0;
  loading: boolean = false;

  constructor(private _FileDurationService: FileDurationService) { }

  ngOnInit() { }

  onChange(e: FileList) {
    this.fileDuration = 0;
    this.loading = true;
    const files = Array.from(e);
    files.forEach(file => {
      this._FileDurationService.getDuration$(file).subscribe((res) => {
        this.fileDuration = Math.ceil(res / 60);
        if (!this.fileDuration) window.alert('Invalid file');
        else {
          this.onFileChange.emit({ duration: this.fileDuration, file: file });
        }
        this.loading = false;
      });
    });
  }
}
