<div class="login-form">
  <ng-container *ngIf="forgotPassword">
    <form class="example-form">
      <mat-form-field class="w-100">
        <input matInput placeholder="Email" autofocus [(ngModel)]="loginData.email" name="email" />
      </mat-form-field>
    </form>
    <button type="submit" mat-raised-button color="primary" class="float-right mt-2" (click)="resetPassword()">
      Proceed
    </button>
  </ng-container>

  <ng-container *ngIf="!forgotPassword">
    <form class="example-form">
      <mat-form-field class="w-100" *ngIf="newUser">
        <input matInput placeholder="Name" autofocus [(ngModel)]="loginData.name" name="name" />
      </mat-form-field>
      <mat-form-field class="w-100">
        <input matInput placeholder="Username" [(ngModel)]="loginData.email" name="email" />
      </mat-form-field>
      <mat-form-field class="w-100">
        <input type="password" matInput placeholder="Password" [(ngModel)]="loginData.password" name="password" />
      </mat-form-field>
    </form>

    <div class="d-flex justify-content-between">
      <mat-checkbox class="example-margin" color="primary" [(ngModel)]="newUser">New User</mat-checkbox>
      <button mat-button color="primary" (click)="onForgot()">Forgot password?</button>
    </div>

    <button type="submit" mat-raised-button color="primary" class="float-right mt-2" (click)="onLogin()"
      *ngIf="!newUser">
      Login
    </button>
    <button type="submit" mat-raised-button color="accent" class="float-right mt-2 mr-2" (click)="signUp()"
      *ngIf="newUser">
      Sign Up
    </button>
  </ng-container>
</div>