import { Injectable } from '@angular/core';
import { ActionWithPayload } from './action-with-payload';
import { User } from '@app/models/user.model';

@Injectable()
export class UserActions {
  static SET_USER_DATA = 'SET_USER_DATA';
  setUserData(data: User): ActionWithPayload<any> {
    return {
      type: UserActions.SET_USER_DATA,
      payload: data,
    };
  }

  static CLEAR_USER_DATA = 'CLEAR_USER_DATA';
  clearUserData(): ActionWithPayload<null> {
    return {
      type: UserActions.CLEAR_USER_DATA,
      payload: null,
    };
  }
}
