<div class="redirecting">
  <mat-card class="text-center" style="font-size: 3rem;">
    <div>
      Hang on...
    </div>
    <div style="font-size: 5rem;">
      🚀
    </div>
    <div>
      Taking you to home..
    </div>
  </mat-card>
</div>
