import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from '../shared/constant';
import { AlertService } from '@app/services/alert.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private bypassedClient: HttpClient;
  constructor(private httpClient: HttpClient, private handler: HttpBackend, private alertService: AlertService) {
    this.bypassedClient = new HttpClient(handler);
  }

  get$(params) {
    const url = environment.api.URL + environment.api.version;
    return new Observable((observer) => {
      this.bypassedClient.post(url + Constant.API.authenticate, params, { observe: 'response' }).subscribe(
        (res: any) => {
          this.alertService.openSnackBar(`Hola! ${res.body.user.name}`, '');
          observer.next(res);
        },
        (err) => {
          this.alertService.openSnackBar('OOPS! invalid username or password.', '');
          observer.error(err);
        }
      );
    });
  }

  create$(params: { name?: string, email?: string, password?: string }) {
    const url = environment.api.URL + environment.api.version;
    return new Observable((observer) => {
      this.bypassedClient.post(url + Constant.API.users, params, { observe: 'response' }).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          this.alertService.openSnackBar(err.error.message, '');
          observer.error(err);
        }
      );
    });
  }

  forgotPassword$(email: string) {
    const url = environment.api.URL + environment.api.version;
    return new Observable((observer) => {
      this.bypassedClient.get(`${url}${Constant.API.forgotPassword}?email=${email}`, { observe: 'response' }).subscribe(
        (res: any) => {
          console.log(res);
          // this.alertService.openSnackBar(`Hola! ${res.body.user.name}`, '');
          observer.next(res);
        },
        (err) => {
          this.alertService.openSnackBar('OOPS! email address doesnt exist', '');
          observer.error(err);
        }
      );
    });
  }

  changePassword$(postData: { oldPassword?: string; newPassword?: string }, userId: number) {
    const url = environment.api.URL + environment.api.version;
    return new Observable((observer) => {
      this.httpClient.post(`${url}${Constant.API.users}/${userId}/changepassword`, postData, { observe: 'response' }).subscribe(
        (res: any) => {
          this.alertService.openSnackBar(`Password changed successfully`, '');
          observer.next(res);
        },
        (err) => {
          this.alertService.openSnackBar(err.error.message, '');
          observer.error(err);
        }
      );
    });
  }

}
