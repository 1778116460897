import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '@app/services/storage.service';
import { CoreState, UserActions } from '@app/store';
import { Store } from '@ngrx/store';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private storageService: StorageService,
    private store: Store<CoreState>,
    private userActions: UserActions
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authenticationService.getToken();
    if (token && !request.headers.has('Authorization')) {
      request = request.clone({
        headers: request.headers.set('Authorization', token),
      });
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.store.dispatch(this.userActions.clearUserData());
              console.log('Error occurred', err.message);
              this.storageService.deleteCookie('DTTOKEN');
              this.storageService.deleteCookie('USER');
              // redirect to the login route
              localStorage.setItem('loggedIn', 'false');
              this.router.navigate(['redirecting']);
            }
          }
        }
      )
    );
  }
}
