import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { PermissionDirective } from './permission.directive';
import { SmallLoadingComponent } from './small-loading/small-loading.component';
import { ModalComponent } from './modal/modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AppCustomInputComponent } from './app-custom-input/app-custom-input.component';
import { LoginFormComponent } from './login-form/login-form.component';

import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { PaymentComponent } from './payment/payment.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { CreditInfoComponent } from './credit-info/credit-info.component';
import { StripeJsComponent } from './stripe-js/stripe-js.component';
import { MatSnackBarModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AppFooterComponent } from '@app/components/app-footer/app-footer.component';
import { ConfirmBoxComponent } from './components/confirm-box/confirm-box.component';

const COMPONENTS = [
  PermissionDirective,
  SmallLoadingComponent,
  ModalComponent,
  FileUploadComponent,
  AppCustomInputComponent,
  LoginFormComponent,
  PaymentComponent,
  PaymentCardComponent,
  CreditInfoComponent,
  StripeJsComponent,
  ChangePasswordComponent,
  AppFooterComponent,
  ConfirmBoxComponent
];

const MATERIAL_MODULES = [
  MatButtonModule,
  MatInputModule,
  MatCardModule,
  MatDividerModule,
  MatTableModule,
  MatIconModule,
  MatRippleModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatSelectModule,
  MatPaginatorModule
];

const DIRECTIVES = [ClickOutsideDirective, AutofocusDirective];

const MODULES = [FormsModule];

@NgModule({
  imports: [CommonModule, FormsModule, ...MODULES, ...MATERIAL_MODULES, ModalModule.forRoot()],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [...COMPONENTS, ...MODULES, ...DIRECTIVES, ...MATERIAL_MODULES],
  providers: [],
})
export class CommonUtilModule { }
