import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalComponent } from '../../common-util/modal/modal.component';
import { Router, NavigationStart } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { User } from '@app/models/user.model';
import { StorageService } from '@app/services/storage.service';
import { FileCacheService } from '@app/services/file-cache.service';
import { filter } from 'rxjs/operators';
import { CoreState, UserActions } from '@app/store';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})

export class AppHeaderComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false }) modal: ModalComponent;
  @ViewChild('loginForm', { static: false }) loginFormComponent: TemplateRef<any>;
  @ViewChild('changePassword', { static: false }) changePasswordTempl: TemplateRef<any>;
  userData: User;
  modalHeading: string = '';
  loggedIn: boolean = false;
  showUserOptions: boolean = false;
  activatedURL: string;
  navLinks: { name: string; link: string }[] = [
    { name: 'Home', link: '' },
    { name: 'Features', link: '/features' },
    { name: 'Industries', link: '/industries' },
    { name: 'FAQ', link: '/faq' },
    { name: 'Get in touch', link: '/contact-us' },
    // { name: 'Demo', link: './transcript/demo' },
  ];
  userOptions: Array<{ label: string; function?: string }> = [
    { label: 'Dashboard', function: 'gotoDashboard' },
    { label: 'Settings', function: 'gotoSettings' },
    { label: 'Checkout', function: 'gotoCheckout' },
    // { label: 'Transactions', function: 'gotoTransactions' },
    { label: 'Change Password', function: 'onChangePassword' },
    { label: 'Logout', function: 'onLogout' },
  ];
  showAllNav: boolean = true;
  constructor(
    private router: Router,
    private store: Store<CoreState>,
    private userActions: UserActions,
    private storageService: StorageService,
    private fileCacheService: FileCacheService,
  ) {
    this.observeRouterEvents();
  }

  ngOnInit() {
    this.observerUserState();
  }

  checkCookies() {
    const checkCookie = this.storageService.getCookie('USER');
    if (checkCookie && !this.userData) {
      this.userData = JSON.parse(checkCookie);
      this.store.dispatch(this.userActions.setUserData(this.userData));
    }
  }

  observerUserState() {
    this.checkCookies();
    this.store.select(s => s).pipe(select(s => s.user)).subscribe(res => {
      this.userData = res;
      this.loggedIn = res.loggedIn;
      if (this.activatedURL === '/' && this.loggedIn) {
        this.gotoDashboard();
      }
    })
  }

  observeRouterEvents() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.checkCookies();
        this.activatedURL = event.url;
        this.toggleNav();
        // You only receive NavigationStart events
      });
  }

  toggleNav() {
    switch (this.activatedURL) {
      case '/':
        this.showAllNav = true;
        break;
      case '/features':
        this.showAllNav = true;
        break;
      case '/industries':
        this.showAllNav = true;
        break;
      case '/faq':
        this.showAllNav = true;
        break;
      case '/contact-us':
        this.showAllNav = true;
        break;
      default: this.showAllNav = false;
    }
  }

  onResetPassword() {
    this.modal.hide();
  }

  onLogin() {
    this.modalHeading = 'Login';
    this.modal.templateRef = this.loginFormComponent;
    this.modal.show();
  }

  onLoginSuccess(e) {
    this.hideModal();
    this.storageService.setCookie('USER', JSON.stringify(e.user));
    this.storageService.setCookie('DTTOKEN', e.token);
    localStorage.setItem('loggedIn', 'true');
    this.store.dispatch(this.userActions.setUserData(e.user));
  }

  hideModal() {
    this.modal.hide();
  }

  onUserOptions(option) {
    this.showUserOptions = false;
    if (option.function) {
      this[option.function]();
    }
  }

  gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }

  gotoHome() {
    this.router.navigate(['/']);
  }

  gotoSettings() {
    this.router.navigate(['/settings']);
  }

  gotoCheckout() {
    this.router.navigate(['/checkout']);
  }

  gotoTransactions() {
    this.router.navigate(['/transactions']);
  }

  onLogout() {
    this.storageService.deleteCookie('USER');
    this.storageService.deleteCookie('DTTOKEN');
    this.userData = null;
    this.store.dispatch(this.userActions.clearUserData());
    this.gotoHome();
  }

  onFileChange(e: { duration: number; file: any }) {
    this.fileCacheService.cacheFile(e);
    setTimeout(() => {
      this.router.navigate([`checkout`], {});
    }, 1000);
  }

  onChangePassword() {
    this.modalHeading = 'Change Password';
    this.modal.templateRef = this.changePasswordTempl;
    this.modal.show();
  }
}
