import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { WalletActions } from "app/store/actions";
import { map, switchMap } from "rxjs/operators";
import { WalletService } from '@app/services/wallet.service';
export class WalletEffects {
    constructor(actions$, walletService, walletActions) {
        this.actions$ = actions$;
        this.walletService = walletService;
        this.walletActions = walletActions;
        this.getWalletData$ = this.actions$
            .pipe(ofType(WalletActions.GET_Wallet_DATA))
            .pipe(map((action) => action.payload), switchMap((payload) => this.walletService.getWalletInfo$(payload)), map((data) => this.walletActions.getWalletDataSuccess(data)));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], WalletEffects.prototype, "getWalletData$", void 0);
