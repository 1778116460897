import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RequestInterceptor } from './shared/request.interceptor';

import { AppRoutingModule } from './app.route';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/authentication.service';
import { StorageService } from './services/storage.service';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers';

// build specific modules
import { buildSpecificModules } from "./build-specifics";


import { effects } from './store/effects';
import { WalletActions, UserActions } from './store';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';
import { AlertService } from './services/alert.service';
import { TranscriptUploadService } from './services/transcript-upload.service';
import { AuthGuard } from './shared/auth.guard';
import { LoginService } from './services/login.service';
import { FullLayoutComponent } from './container/full-layout/full-layout.component';
import { AppHeaderComponent } from './components';
import { RedirectingPageComponent } from './components/redirecting-page/redirecting-page.component';
import { CommonUtilModule } from './common-util/common-util.module';
import { ContactUsService } from './services/contact-us.service';
import { PendingChangesGuard } from './services/component-can-deactivate.service';

const HttpRequestInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestInterceptor,
  multi: true,
};

const CONTAINERS = [
  FullLayoutComponent,
  AppHeaderComponent,
  RedirectingPageComponent
]

const Services = [
  AuthenticationService,
  LoginService,
  StorageService,
  DynamicScriptLoaderService,
  AlertService,
  TranscriptUploadService,
  ContactUsService,
  PendingChangesGuard
];

const StoreActions = [WalletActions, UserActions]

@NgModule({
  declarations: [AppComponent, ...CONTAINERS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonUtilModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([...effects]),
    // EffectsModule.forFeature(effects),
    ...buildSpecificModules
  ],
  providers: [HttpRequestInterceptor, AuthGuard, ...Services, ...StoreActions],
  bootstrap: [AppComponent],
})
export class AppModule { }
