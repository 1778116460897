/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-layout.component..scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/app-header/app-header.component.ngfactory";
import * as i3 from "../../components/app-header/app-header.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "../../store/actions/user.actions";
import * as i7 from "../../services/storage.service";
import * as i8 from "../../services/file-cache.service";
import * as i9 from "./full-layout.component";
var styles_FullLayoutComponent = [i0.styles];
var RenderType_FullLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
export function View_FullLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 78px; background-color: #044d55;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_AppHeaderComponent_0, i2.RenderType_AppHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.AppHeaderComponent, [i4.Router, i5.Store, i6.UserActions, i7.StorageService, i8.FileCacheService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "main", [["class", "app-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); }, null); }
export function View_FullLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i9.FullLayoutComponent, [], null, null)], null, null); }
var FullLayoutComponentNgFactory = i1.ɵccf("app-root", i9.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
