import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileCacheService {
  files: any[] = [];

  constructor() { }

  getFiles$() {
    return [...this.files];
    // return this.file$.getValue();
  }

  cacheFile(data: { duration: number; file: any }) {
    this.files.push(data);
  }

  clearFiles() {
    this.files = [];
  }

  updateFiles(files) {
    this.files = files;
  }

}
