import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { PaymentsService } from "@app/services/payments.service";
import { SavedCard } from '@app/models/saved-card';
import { CardsService } from '@app/services/cards.service';
import { WalletService } from '@app/services/wallet.service';
import { CoreActions } from '@ngrx/store-devtools/src/reducer';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store';
import { User } from '@app/models/user.model';

declare var elements: any;
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent {
  @ViewChild("cardInfo", { static: false }) cardInfo: ElementRef;

  card: any;
  error: string;
  defaultCard: string;
  savedCards: SavedCard[]
  saveCard: boolean = true;
  selectedCardId: string = '';
  savedCardSelected: boolean = true;
  cardHandler = this.onChange.bind(this);
  user: User;

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  @Input() amount: number = 0;

  constructor(
    private cd: ChangeDetectorRef,
    private cardsService: CardsService,
    private paymentsService: PaymentsService,
    private walletService: WalletService,
    private store: Store<CoreState>
  ) {
    this.observeUserState();
    this.getCards();
  }


  observeUserState() {
    this.store.select(s => s.user).subscribe(res => {
      this.user = res;
    })
  }


  getCards() {
    this.cardsService.getCards$().subscribe((res) => {
      this.savedCardSelected = res.savedCards && res.savedCards.length ? true : false
      this.defaultCard = res.defaultCard ? res.defaultCard : '';
      this.selectedCardId = this.defaultCard
      this.savedCards = res.savedCards ? res.savedCards : [];
    })
  }

  createStripeElement() {
    this.card = elements.create("card");
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  paymentOptionChange(savedCardSelected, cardId) {
    this.savedCardSelected = savedCardSelected;
    this.selectedCardId = cardId;
    if (this.savedCardSelected) this.saveCard = false;
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  onSuccess() {
    this.savedCardSelected = true;
    setTimeout(() => {
      this.success.emit();
    }, 300);
  }

  onCancel() {
    this.cancel.emit();
  }

  createPayment(paymentToken: { id: string, address: any }, newCard) {
    this.paymentsService
      .createPayment$({ paymentToken: paymentToken.id, amount: this.amount, saveCard: this.saveCard, newCard })
      .subscribe(res => {
        this.walletService.refreshWalletData(this.user.id);
        this.onSuccess();
      });
  }

  changeSaveCard(e) {
    this.saveCard = e;
  }
}
