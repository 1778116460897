import * as tslib_1 from "tslib";
import { EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';
export class StripeJsComponent {
    constructor(cd) {
        this.cd = cd;
        this.onSaveCard = new EventEmitter();
        this.token = new EventEmitter();
        this.mode = 'pay';
        this.amount = 0;
        this.cardHandler = this.onChange.bind(this);
        this.savedCardSelected = '';
        this.saveCard = true;
        this.isValid = false;
        this.address = {};
        // pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U
        // pk_live_ZLp10P4YndHd5utZEn3QwgAc00gCTdg4b5
        // var stripe = stripe("pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U");
        // var elements = stripe.elements();
    }
    ngAfterViewInit() {
        this.card = elements.create("card");
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener("change", this.cardHandler);
    }
    onChange({ error }) {
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    }
    onSubmit(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { token, error } = yield stripe.createToken(this.card, this.address);
            if (!error) {
                this.emitToken(token);
                this.card.clear();
            }
            else {
                Swal.fire('Error', error.message, 'error');
                // throw error('Something went wrong.');
            }
        });
    }
    emitToken(token) {
        this.address = {};
        this.token.emit({ id: token.id, address: this.address });
    }
    ngOnDestroy() {
        this.card.destroy();
    }
    changeSaveCard(e) {
        this.saveCard = e;
        this.onSaveCard.emit(e);
    }
    validate() {
        this.isValid = Object.keys(this.address).every(el => this.address[el]);
    }
}
