import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirecting-page',
  templateUrl: './redirecting-page.component.html',
  styleUrls: ['./redirecting-page.component.scss'],
})
export class RedirectingPageComponent implements OnInit {
  constructor(private _Router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this._Router.navigate(['']);
    }, 3000);
  }
}
