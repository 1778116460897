import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { ActionWithPayload, WalletActions } from "app/store/actions";
import { map, switchMap } from "rxjs/operators";
import { WalletService } from '@app/services/wallet.service';
import { Wallet } from '@app/models/wallet';

@Injectable()
export class WalletEffects {
	constructor(
		private actions$: Actions,
		private walletService: WalletService,
		private walletActions: WalletActions
	) { }

	@Effect()
	getWalletData$ = this.actions$
		.pipe(ofType(WalletActions.GET_Wallet_DATA))
		.pipe(
			map((action: ActionWithPayload<null>) => action.payload),
			switchMap((payload) => this.walletService.getWalletInfo$(payload)),
			map((data: Wallet) => this.walletActions.getWalletDataSuccess(data)
			)
		);
}
