import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'app-stripe-js',
  templateUrl: './stripe-js.component.html',
  styleUrls: ['./stripe-js.component.scss']
})
export class StripeJsComponent implements OnDestroy {
  @ViewChild("cardInfo", { static: false }) cardInfo: ElementRef;

  @Output() onSaveCard: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() token: EventEmitter<{ id: string; address: any }> = new EventEmitter<{ id: string; address: any }>();

  @Input() mode: 'pay' | 'add_card' = 'pay';
  @Input() amount: number = 0;

  cardHandler = this.onChange.bind(this);
  savedCardSelected: string = '';
  saveCard: boolean = true;
  isValid: boolean = false;
  error: string;
  address: {
    name?: string,
    address_city?: string,
    address_state?: string,
    country?: string,
    address_zip?: string
  } = {};
  card: any;

  constructor(
    private cd: ChangeDetectorRef,
  ) {
    // pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U
    // pk_live_ZLp10P4YndHd5utZEn3QwgAc00gCTdg4b5
    // var stripe = stripe("pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U");
    // var elements = stripe.elements();
  }

  ngAfterViewInit() {
    this.card = elements.create("card");
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const { token, error } = await stripe.createToken(this.card, this.address);
    if (!error) {
      this.emitToken(token);
      this.card.clear();
    } else {
      Swal.fire(
        'Error',
        error.message,
        'error'
      );
      // throw error('Something went wrong.');
    }
  }

  emitToken(token) {
    this.address = {};
    this.token.emit({ id: token.id, address: this.address });
  }

  ngOnDestroy() {
    this.card.destroy();
  }

  changeSaveCard(e) {
    this.saveCard = e;
    this.onSaveCard.emit(e);
  }

  validate() {
    this.isValid = Object.keys(this.address).every(el => this.address[el]);
  }
}
