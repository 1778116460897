/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "card p-0"], ["style", "min-width: fit-content;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"], ["style", "color: #fff;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body m-2"], ["style", "max-height: calc(100vh - 170px);overflow-y: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(9, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateRef; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { template: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["modalTemplate", 2]], null, 0, null, View_ModalComponent_1))], null, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵprd(512, null, i3.BsModalService, i3.BsModalService, [i1.RendererFactory2, i4.ComponentLoaderFactory]), i1.ɵdid(2, 49152, null, 0, i5.ModalComponent, [i3.BsModalService], null, null)], null, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i5.ModalComponent, View_ModalComponent_Host_0, {}, { onHidden: "onHidden", onCancel: "onCancel" }, ["[heading]"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
