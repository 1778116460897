import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Constant } from '../shared/constant';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../models/user.model';

@Injectable()
export class AuthenticationService {
  private apiURL = environment.api.URL;
  constructor(private storage: StorageService, private httpClient: HttpClient) {}

  getToken() {
    return this.storage.getCookie(environment.api.tokenLabel);
  }

  getUser$(): Observable<User> {
    return new Observable((observable) => {
      this.httpClient.get(this.apiURL + Constant.API.authenticate).subscribe(
        (data) => {
          this.storage.setCookie('user', JSON.stringify(data));
          observable.next(new User(data));
          this.storage.setCookie('loggedInUser', JSON.stringify(data));
        },
        (err) => {
          observable.error(err);
        }
      );
    });
  }
}
