import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import Swal from "sweetalert2";
import { StorageService } from '@app/services/storage.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output() onSuccess: EventEmitter<null> = new EventEmitter<null>();
  password: { oldPassword?: string; newPassword?: string; } = { oldPassword: '', newPassword: '' };
  isValid: boolean = false;
  constructor(
    private loginService: LoginService,
    private storageService: StorageService
  ) { }

  ngOnInit() { }

  resetForm() {
    this.password = { oldPassword: '', newPassword: '' };
  }

  changePassword() {
    const userId = JSON.parse(this.storageService.getCookie(environment.api.userLabel)).id;
    this.loginService.changePassword$(this.password, userId).subscribe(res => {
      this.resetForm();
      this.onSuccess.emit();
    })
  }

  validate() {
    this.isValid = Object.keys(this.password).every(el => this.password[el]);
  }
}
