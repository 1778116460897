import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from 'app/shared/constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContactUsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    create$(param) {
        const url = environment.api.URL + environment.api.version;
        return new Observable((observer) => {
            this.httpClient.post(`${url}${Constant.API.contactUs}`, param).subscribe((res) => {
                observer.next(res);
            }, (err) => {
                observer.error(err);
            });
        });
    }
}
ContactUsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactUsService_Factory() { return new ContactUsService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactUsService, providedIn: "root" });
