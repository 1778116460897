import { Injectable } from '@angular/core';
import { HttpEventType, HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { Constant } from '@app/shared/constant';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranscriptUploadService {
  _httpHeaders: any;
  constructor(private handler: HttpBackend,
    private alertService: AlertService,
    private httpClient: HttpClient
  ) {
    this._httpHeaders = {
      headers: new HttpHeaders({}),
      reportProgress: true,
      observe: 'events',
    };
  }
  uploadFile$(jobId, script): Observable<any> {
    const body = new FormData();
    body.append("file", JSON.stringify(script));
    const url = environment.api.URL + environment.api.version;
    return new Observable((observable) => {
      this.httpClient.post(`${url}${Constant.API.jobs}/${jobId}/${Constant.API.transcribe.get}`, body).subscribe(
        (res) => {
          observable.next(res);
        },
        (err) => {
          observable.error(err);
        }
      );
    });
  }

}
