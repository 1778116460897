import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { Constant } from '@app/shared/constant';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
export class TranscriptUploadService {
    constructor(handler, alertService, httpClient) {
        this.handler = handler;
        this.alertService = alertService;
        this.httpClient = httpClient;
        this._httpHeaders = {
            headers: new HttpHeaders({}),
            reportProgress: true,
            observe: 'events',
        };
    }
    uploadFile$(jobId, script) {
        const body = new FormData();
        body.append("file", JSON.stringify(script));
        const url = environment.api.URL + environment.api.version;
        return new Observable((observable) => {
            this.httpClient.post(`${url}${Constant.API.jobs}/${jobId}/${Constant.API.transcribe.get}`, body).subscribe((res) => {
                observable.next(res);
            }, (err) => {
                observable.error(err);
            });
        });
    }
}
TranscriptUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranscriptUploadService_Factory() { return new TranscriptUploadService(i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i1.HttpClient)); }, token: TranscriptUploadService, providedIn: "root" });
