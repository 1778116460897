<div class="change-password-component">
    <form class="example-form">
        <mat-form-field class="w-100">
            <input type="password" matInput placeholder="Old Password" autofocus [(ngModel)]="password.oldPassword"
                (ngModelChange)="validate();" name="oldPassword" />
        </mat-form-field>
        <mat-form-field class="w-100">
            <input type="password" matInput placeholder="New Password" [(ngModel)]="password.newPassword"
                (ngModelChange)="validate();" name="newPassword" />
        </mat-form-field>
    </form>
    <button type="submit" mat-raised-button color="primary" class="float-right mt-2" [disabled]="!isValid"
        (click)="changePassword()">
        Proceed
    </button>
</div>