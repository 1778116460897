import * as i0 from "@angular/core";
export class FileCacheService {
    constructor() {
        this.files = [];
    }
    getFiles$() {
        return [...this.files];
        // return this.file$.getValue();
    }
    cacheFile(data) {
        this.files.push(data);
    }
    clearFiles() {
        this.files = [];
    }
    updateFiles(files) {
        this.files = files;
    }
}
FileCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileCacheService_Factory() { return new FileCacheService(); }, token: FileCacheService, providedIn: "root" });
