<div class="app-header">
  <nav class="d-flex justify-content-between align-items-center">

    <div class="menu-logo">
      <div class="navbar-brand">
        <span class="navbar-logo">
          <a href="javascript:;" [routerLink]="['/']"><img src="../../../assets/img/dt_logo.png" alt="DT_LOGO"
              title="HOME" style="height: 3.8rem;" /></a>
        </span>
      </div>
    </div>

    <ul>
      <li>
        <app-file-upload (onFileChange)="onFileChange($event)"
          [ngClass]="{'d-none': showAllNav && activatedURL !== '/checkout'}">
        </app-file-upload>
      </li>
      <ng-container *ngIf="showAllNav">
        <li *ngFor="let nav of  navLinks">
          <a [routerLink]="[nav.link]">{{nav.name}}</a>
        </li>
        <li>
          <!-- <a [routerLink]="['./transcript/demo']">Demo</a> -->
          <a target="_blank" href="/transcript/demo">Demo</a>
        </li>
      </ng-container>
      <li *ngIf="!loggedIn" id="login-button" (click)="onLogin()">
        <a href="javascript:;">Login</a>
      </li>
      <li *ngIf="loggedIn" (click)="showUserOptions = !showUserOptions" style="position:relative"
        (clickOutside)="showUserOptions = false">
        <a href="javascript:;">{{ userData.name }}</a>
        <div class="user-options" *ngIf="showUserOptions">
          <ng-container *ngTemplateOutlet="userOptionsTemplate"></ng-container>
        </div>
      </li>
    </ul>
  </nav>

  <ng-template #userOptionsTemplate>
    <ul>
      <ng-container *ngFor="let option of userOptions; let last = last;">
        <li (click)="onUserOptions(option)">{{ option.label }}</li>
      </ng-container>
    </ul>
  </ng-template>

  <ng-template #loginForm>
    <app-login-form (onSuccess)="onLoginSuccess($event)" (onResetPassword)="onResetPassword()"></app-login-form>
  </ng-template>

  <ng-template #changePassword>
    <app-change-password (onSuccess)="hideModal();"></app-change-password>
  </ng-template>

  <app-modal>
    <div heading>
      {{ modalHeading }}
    </div>
  </app-modal>
</div>