import { ActionWithPayload } from '../actions';
import { UserActions } from '../actions';
import { User } from '@app/models/user.model';
export interface UserState {
  name: string;
  email: string;
  id: number;
  loggedIn: boolean;
}

export const userInitialState: UserState = {
  name: '',
  email: '',
  id: null,
  loggedIn: false,
};

export function userReducer(state: UserState = userInitialState, action: ActionWithPayload<User>): UserState {
  switch (action.type) {
    case UserActions.SET_USER_DATA:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        id: action.payload.id,
        loggedIn: true,
      };
    case UserActions.CLEAR_USER_DATA:
      return { ...state, name: '', email: '', id: null, loggedIn: false };
    default:
      return state;
  }
}

// functions to access state
// export const getPhotoFilterLoading = (state: PhotoFilterState) => state;
