import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Constant } from '@app/shared/constant';
import { Wallet } from '@app/models/wallet';
import { WalletActions, CoreState } from '@app/store';
import { Store } from '@ngrx/store';
import { AlertService } from './alert.service';
import { User } from '@app/models/user.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private apiURL = environment.api.URL;
  constructor(
    private http: HttpClient,
    private store: Store<{ appState: CoreState }>,
    private walletActions: WalletActions,
    private alertService: AlertService,
  ) { }

  getWalletInfo$(userId): Observable<Wallet> {
    return new Observable(observer => {
      const url = `${this.apiURL}${environment.api.version}${Constant.API.users}/${userId}/${Constant.API.wallet}`;
      this.http.get(url).subscribe(
        (res: Wallet) => {
          observer.next(res);
        },
        err => observer.error(err))
    })
  }

  refreshWalletData(userId) {
    this.store.dispatch(this.walletActions.getWalletData(userId));
  }

  updateWalletSetting$(userId, params: { autoReload?: boolean; reloadThreshold?: number; reloadAmount?: number; }) {
    return new Observable(observer => {
      this.http.put(this.apiURL + environment.api.version + Constant.API.users + '/' + userId, params)
        .subscribe((data: User) => {
          observer.next(data);
          this.alertService.openSnackBar('Wallet settings updated', '');
        },
          err => {
            this.alertService.openSnackBar('Failed', '');
            observer.error(err);
          }
        );
    });
  }
}
