import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileDurationService {
  constructor() { }

  getDuration$(file): Observable<number> {
    // https://codepen.io/sharma-sk/pen/bVxyRm?editors=1010
    return new Observable((observer) => {
      let reader = new FileReader();
      // console.log(file);
      reader.readAsDataURL(file);
      reader.onload = function (event: any) {
        // console.log(event);
        if (file.type == 'video/mp4' || file.type == 'video/ogg' || file.type == 'video/webm') {
          var videoElement = document.createElement('video');
          videoElement.src = event.target.result;
          var timer = setInterval(function () {
            if (videoElement.readyState === 4) {
              observer.next(videoElement.duration);
              observer.complete();
              // let getTime = this.secondsToTime(videoElement.duration);
              // console.log(getTime);
              clearInterval(timer);
            }
          }, 500);
        } else if (file.type == 'audio/mp3' || file.type == 'audio/mpeg' || file.type == 'audio/wav' || file.type == 'audio/ogg') {
          var audioElement = document.createElement('audio');
          audioElement.src = event.target.result;
          var timer = setInterval(function () {
            if (audioElement.readyState === 4) {
              observer.next(audioElement.duration);
              observer.complete();
              // let getTime = this.secondsToTime(audioElement.duration);
            }
            clearInterval(timer);
          }, 500);
        } else {
          observer.next(0);
          observer.complete();
        }
      };
    });
  }
  secondsToTime(in_seconds) {
    let time = '';
    in_seconds = parseFloat(in_seconds.toFixed(2));

    let hours: any = Math.floor(in_seconds / 3600);
    let minutes: any = Math.floor((in_seconds - hours * 3600) / 60);
    let seconds: any = in_seconds - hours * 3600 - minutes * 60;
    //seconds = Math.floor( seconds );
    seconds = seconds.toFixed(0);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    time = minutes + ':' + seconds;

    return time;
  }
}
